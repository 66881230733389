import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavHashLink } from 'react-router-hash-link';

import Header1 from '../../assets/images/header1.jpg'
import Header2 from '../../assets/images/header2.jpg'
import Header3 from '../../assets/images/header3.jpg'
import Services1 from "../../assets/images/services1.png"
import Services2 from "../../assets/images/services2.jpg"
import Services3 from "../../assets/images/services3.png"
import Services4 from "../../assets/images/services4.jpg"
import Services5 from "../../assets/images/services5.jpg"
import Services6 from "../../assets/images/services6.jpg"
import Services7 from "../../assets/images/services7.jpg"
import Services8 from "../../assets/images/services8.jpg"
import project1 from "../../assets/images/project1.jpeg"
import project2 from "../../assets/images/project2.jpeg"
import project3 from "../../assets/images/project3.jpeg"
import project4 from "../../assets/images/project4.jpeg"
import project5 from "../../assets/images/project5.jpeg"
import project6 from "../../assets/images/project6.jpeg"
import project7 from "../../assets/images/project7.jpeg"
import project8 from "../../assets/images/project8.jpeg"
import project9 from "../../assets/images/project9.jpeg"
import project10 from "../../assets/images/project10.jpg"
import project11 from "../../assets/images/project11.jpg"
import project12 from "../../assets/images/project12.jpeg"
import project13 from "../../assets/images/project13.jpeg"
import project14 from "../../assets/images/project14.jpeg"
import Landmark from '../../assets/images/Landmark.png'
import Ascendem from '../../assets/images/ascendem.png'
import Detel from '../../assets/images/detel.jpg'
import Elsewedy from '../../assets/images/elsewedy.jpg'
import Hoi from '../../assets/images/Hoi.jpg'
import Mercury from '../../assets/images/Mercury.png'
import Vodafone from '../../assets/images/Vodafone.png'
import Orange from '../../assets/images/Orange.png'
import Etisalat from '../../assets/images/Etisalat.png'
import Nokia from '../../assets/images/Nokia.png'
import Huawei from '../../assets/images/huawei.png'
import ZTE from '../../assets/images/ZTE.png'
import Ericsson from '../../assets/images/Ericsson.png'

export default function Home() {
    const location = 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3548.700452328382!2d31.178494999999998!3d27.197149999999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDExJzQ5LjciTiAzMcKwMTAnNDIuNiJF!5e0!3m2!1sen!2seg!4v1712577062978!5m2!1sen!2seg'

    useEffect(()=>{
        AOS.init({
          delay: 100, // values from 0 to 3000, with step 50ms
          duration: 800, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-sine', // default easing for AOS animations
        //   disable: 'mobile'
        });
      })

    return (
        <>
            <header id="sec-header" className="sec-header">
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active position-relative">
                            <img src={Header1} className="d-block w-100" alt="Header 1"/>
                            <div className='position-absolute top-50 start-50 translate-middle w-75'>
                                <div className='title'>
                                    <span className='fw-bold fs-3'>Telecommunications Department - Microwave Installation</span>
                                </div>
                                <div>
                                    <span className='text-white fw-bold fs-5'>Our team possess expertise in handling the installation of microwave antennas, towers, and associated equipment, ensuring precise alignment and secure mounting, commissioning, configurations, and troubleshooting.</span>
                                </div>
                                <div className='pt-3'>
                                    <NavHashLink className='text-white py-2 px-3 border-rounded' to={'/services#Services1'}>More</NavHashLink>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item position-relative">
                            <img src={Header2} className="d-block w-100" alt="Header 2"/>
                            <div className='position-absolute top-50 start-50 translate-middle w-75'>
                                <div className='title'>
                                    <span className='fw-bold fs-3'>RAN Installation</span>
                                </div>
                                <div>
                                    <span className='text-white fw-bold fs-5'>Our team consists of highly skilled professionals who are proficient in the latest RAN technologies.</span>
                                </div>
                                <div className='pt-3'>
                                    <NavHashLink className='text-white py-2 px-3 border-rounded' to={'/services#Services2'}>More</NavHashLink>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item position-relative">
                            <img src={Header3} className="d-block w-100" alt="Header 3"/>
                            <div className='position-absolute top-50 start-50 translate-middle w-75'>
                                <div className='title'>
                                    <span className='fw-bold fs-3'>Network Roll-out</span>
                                </div>
                                <div>
                                    <span className='text-white fw-bold fs-5'>Our company specializes in Telecom Rollout Installation services, providing network energy installation services for telecommunication towers and offers comprehensive Managed Services for telecom networks.</span>
                                </div>
                                <div className='pt-3'>
                                    <NavHashLink className='text-white py-2 px-3 border-rounded' to={'/services#Services3'}>More</NavHashLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </header>
            
            <section className='sec-home-about my-5' id="sec-home-about" data-aos="fade-down">
                <div className='container'>
                    <div className='title d-flex justify-content-center py-4'>
                        <span>ABOUT US</span>
                    </div>
                    <div className='m-auto w-75 text-center mb-5'>
                        <span className='text-secondary fs-4'>Established in Assiut, Egypt in 2016, our company was founded by telecommunications experts with a shared goal: to introduce exceptional services to our valued customers. With a commitment to excellence and a passion for innovation, we aim to make a positive impact in telecom services, including new networks implementation, networks expansion, and light civil work. Our highly skilled team tirelessly works to understand and meet our customers' needs, combining technical proficiency with a deep understanding of their requirements to ensure the delivery of reliable and cost-effective telecommunications services.</span>
                    </div>
                    <div className='my-5 text-center'>
                        <div className='project-btn'>
                            <NavHashLink to='/about#about' className='py-3 px-4 border-rounded text-white'>More About Us</NavHashLink>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sec-home-services py-4' id="sec-home-services" data-aos="zoom-in-down">
                <div className='container'>
                    <div className='title d-flex justify-content-center my-5'>
                        <span>OUR SERVICES</span>
                    </div>
                    <div className='m-auto w-75 text-center mb-5'>
                        <span className='fs-4'>we are building  your dream network</span>
                    </div>
                    <div className='row gy-4 m-0'>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <div className='border-0 rounded-3 position-relative project'>
                                <img src={Services1} alt="project 1" className='w-100 border-rounded'/>
                                <div className='project-txt'>
                                    <div>
                                        <NavHashLink to='/services#Services1' className='text-white' >Telecommunication department</NavHashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <div className='border-0 rounded-3 position-relative project'>
                                <img src={Services2} alt="project 1" className='w-100 border-rounded'/>
                                <div className='project-txt'>
                                    <div>
                                        <NavHashLink to='/services#Services2' className='text-white' >RAN Installation</NavHashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <div className='border-0 rounded-3 position-relative project'>
                                <img src={Services3} alt="project 1" className='w-100 border-rounded'/>
                                <div className='project-txt'>
                                    <div>
                                        <NavHashLink to='/services#Services3' className='text-white' >Network Roll-out</NavHashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <div className='border-0 rounded-3 position-relative project'>
                                <img src={Services4} alt="project 1" className='w-100 border-rounded'/>
                                <div className='project-txt'>
                                    <div>
                                        <NavHashLink to='/services#Services4' className='text-white' >Network energy</NavHashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <div className='border-0 rounded-3 position-relative project'>
                                <img src={Services5} alt="project 1" className='w-100 border-rounded'/>
                                <div className='project-txt'>
                                    <div>
                                        <NavHashLink to='/services#Services5' className='text-white' >Managed  Services (MS)</NavHashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <div className='border-0 rounded-3 position-relative project'>
                                <img src={Services6} alt="project 1" className='w-100 border-rounded'/>
                                <div className='project-txt'>
                                    <div>
                                        <NavHashLink to='/services#Services6' className='text-white' >Civil department</NavHashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <div className='border-0 rounded-3 position-relative project'>
                                <img src={Services7} alt="project 1" className='w-100 border-rounded'/>
                                <div className='project-txt'>
                                    <div>
                                        <NavHashLink to='/services#Services7' className='text-white' >Light civil constructions</NavHashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <div className='border-0 rounded-3 position-relative project'>
                                <img src={Services8} alt="project 1" className='w-100 border-rounded'/>
                                <div className='project-txt'>
                                    <div>
                                        <NavHashLink to='/services#Services8' className='text-white' >Supplying Department</NavHashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className='my-5 text-center'>
                        <div className='project-btn'>
                            <NavHashLink to='/services#services' className='py-3 px-4 border-rounded text-white'>More Services</NavHashLink>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sec-home-projects my-5' id='sec-home-projects' data-aos="zoom-in-down">
                <div className='container'>
                    <div className='title d-flex justify-content-center py-5'>
                        <span>OUR Projects</span>
                    </div>
                    <div className='m-auto w-75 text-center mb-5'>
                        <span className='fs-4'>As an example, but not limited to, Upper Telecom has completed over 2000 site expansions for various technologies, including 900 MHz, 1800 MHz, 3G, and 2600 MHz, for different operators such as Vodafone, e& Egypt, and Orange. We have also implemented approximately 300 Microwave (MW) links with different vendors and delivered around 100 site rollouts</span>
                    </div>
                    <div className='row g-3 m-0'>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project1} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project2} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project3} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project4} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project5} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project6} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project7} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project8} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project9} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project10} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project11} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project12} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project13} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-6 col-sm-4 col-lg-3'>
                            <img src={project14} alt='Landmark' className='landmark'/>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sec-home-clients py-4' id="sec-home-clients" data-aos="zoom-in-down">
                <div className='container'>
                    <div className='title d-flex justify-content-center py-5'>
                        <span>OUR Clients</span>
                    </div>
                    <div className='m-auto w-75 text-center'>
                        <span className='fs-4'>Upper Telcom done more than 2000 Sites Ran expansion for different technology 900 MHZ, 1800 MHZ, 3G, L2600 under different operator (Vodafone, Etisalat Misr, Orange)</span>
                    </div>
                    <div className='m-auto w-50 text-center'>
                        <span className='fs-4'>Implemented around 300 MW link with different vendor.</span>
                    </div>
                    <div className='m-auto w-50 text-center mb-5'>
                        <span className='fs-4'>Delivery  around 100 Sites Roll out.</span>
                    </div>
                    <div className='row g-1 m-0'>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Landmark} alt='Landmark' className='landmark'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Ascendem} alt='Ascendem'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Detel} alt='Detel'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Elsewedy} alt='Elsewedy'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Hoi} alt='Hoi'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Mercury} alt='Mercury'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Vodafone} alt='Vodafone'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Orange} alt='Orange'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Etisalat} alt='Etisalat'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Nokia} alt='Nokia'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Huawei} alt='Huawei'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={ZTE} alt='ZTE'/>
                        </div>
                        <div className='d-flex justify-content-center col-4 col-sm-2'>
                            <img src={Ericsson} alt='Ericsson' className='Ericsson'/>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className='sec-home-contact pt-4' id="sec-home-contact"  data-aos="fade-down">
                <div className='container'>
                    <div className='title d-flex justify-content-center py-5'>
                        <span>CONTACT US</span>
                    </div>
                    <div className='row m-0 g-3'>
                        <div className='col-12 col-lg-6'>
                            <div className='d-flex justify-content-center align-content-center flex-column text-center pb-2'>
                                <div className='d-flex mx-2 my-5 m-lg-5'>
                                    <span className='bg-color p-2 d-flex justify-content-center align-items-center' >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                                        </svg>
                                    </span>
                                    <span className='mt-1 ms-5 fs-4 fw-bold text-start'>
                                        <a href='https://www.facebook.com/UpperTelecomEg/'
                                        target="_blank" rel="noreferrer" className='span-color txt'>UpperTelecomEg</a>
                                    </span>
                                </div>
                                <div className='d-flex mx-2 my-5 m-lg-5'>
                                    <span className='bg-color p-2 d-flex justify-content-center align-items-center' >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fillRule="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                    </span>
                                    <span className='mt-1 ms-5 fs-4 fw-bold text-start txt'>4 Street no. 7, Takseem Elhokokyeen, Assiut, Egypt.</span>
                                </div>
                                <div className='d-flex mx-2 my-5 m-lg-5'>
                                    <span className='bg-color p-2 d-flex justify-content-center align-items-center' >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fillRule="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                        </svg>
                                    </span>
                                    <span className='mt-1 ms-5 fs-4 fw-bold text-start phone'>
                                        +201016304962
                                        /
                                        +201061780722
                                    </span>
                                </div>
                                <div className='d-flex mx-2 my-5 m-lg-5'>
                                    <span className='bg-color p-2 d-flex justify-content-center align-items-center' >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fillRule="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                        </svg>
                                    </span>
                                    <span className='mt-1 ms-5 fs-4 fw-bold  text-start email' style={{'word-break': 'break-word'}}>uppertelecomteam@uppertelecom.net</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 mb-5'>
                            <div>
                                <iframe src={location} width="100%" height="600" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='NMH Location'></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
