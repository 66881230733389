import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import Services1 from "../../assets/images/services1.png"
import Services2 from "../../assets/images/services2.jpg"
import Services3 from "../../assets/images/services3.png"
import Services4 from "../../assets/images/services4.jpg"
import Services5 from "../../assets/images/services5.jpg"
import Services6 from "../../assets/images/services6.jpg"
import Services7 from "../../assets/images/services7.jpg"
import Services72 from "../../assets/images/services7-2.jpg"
import Services8 from "../../assets/images/services8.jpg"

export default function Services() {

  useEffect(()=>{
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-sine', // default easing for AOS animations
    });
  })

  return (
    <section className='services' id="services">
        <div className='page-title title d-flex justify-content-center py-5'>
            <span className='mt-5 text-white fs-1'>OUR SERVICES</span>
        </div>
        <div className='container mt-5'>
            <div className='m-auto w-75 text-center'>
                <span className='text-secondary fs-3'>we are building  your dream network</span>
            </div>
            <div id='Services1' className='py-5'>
              <div className='row mx-0 mt-3 g-4' data-aos="fade-right">
                <div className='col-12 col-lg-6 order-2 order-lg-1 image'>
                  <img src={Services1} alt="Services 1" className='w-100 border-rounded'/>
                </div>
                <div className='col-12 col-lg-6 order-1 order-lg-2'>
                  <div className='title d-flex justify-content-start py-3'>
                      <span>Telecommunication department</span>
                  </div>
                  <div>
                    <span className='d-block pb-1 text-secondary fw-bold'>Microwave installation</span>
                    <span className='d-block pb-2 text-secondary'>Our team possess expertise in handling the installation of microwave antennas, towers, and associated equipment, ensuring precise alignment and secure mounting, commissioning, configurations, and troubleshooting. They proficiently manage backhaul links and work with antennas of various sizes from reputable vendors such as Huawei, Ericsson, Nokia, ZTE, and others.</span>
                    <span className='d-block pb-3 text-secondary'>We provide ongoing technical support to ensure the smooth operation of your microwave link service. Our dedicated support team is available to address and deal with any issues or concerns that may arise, ensuring minimal disruption to your business operations.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div id='Services2' className='py-5'>
              <div className='row mx-0 mt-3 g-4' data-aos="fade-right">
                <div className='col-12 col-lg-6'>
                  <div className='title d-flex justify-content-start py-3'>
                      <span>RAN Installation</span>
                  </div>
                  <span className='d-block pb-3 text-secondary'>Our team consists of highly skilled professionals who are proficient in the latest RAN (Radio Access Network) technologies and industry best practices. With their deep knowledge and hands-on experience, we ensure a seamless installation process that adheres to the highest quality standards. Our team is experienced in all generations of cellular technology, including 2G, 3G, 4G, and 5G. They are well-versed in the installation standards, commissioning, configuration, and troubleshooting of equipment from various manufacturers such as Huawei, Ericsson, ZTE, Nokia, and others.</span>
                </div>
                <div className='col-12 col-lg-6 image'>
                  <img src={Services2} alt="Services 2" className='w-100 border-rounded'/>
                </div>
              </div>
            </div>
            <div id='Services3' className='py-5'>
              <div className='row mx-0 mt-3 g-4' data-aos="fade-right">
                <div className='col-12 col-lg-6 order-2 order-lg-1 image'>
                  <img src={Services3} alt="Services 3" className='w-100 border-rounded'/>
                </div>
                <div className='col-12 col-lg-6 order-1 order-lg-2'>
                  <div className='title d-flex justify-content-start py-3'>
                      <span>Network Roll-out</span>
                  </div>
                  <span className='d-block pb-3 text-secondary'>Our company specializes in Telecom Rollout Installation services. As a prominent provider in the telecommunications industry, we boast a proven track record of successfully executing installations for telecom rollout projects of various scales and complexities. This includes the integration of power cabinet and battery systems. We design and install power cabinets and battery systems to deliver essential power backup, surge protection, and remote monitoring capabilities.</span>
                </div>
              </div>
            </div>
            <div id='Services4' className='py-5'>
              <div className='row mx-0 mt-3 g-4' data-aos="fade-right">
                <div className='col-12 col-lg-6'>
                  <div className='title d-flex justify-content-start py-3'>
                      <span>Network energy</span>
                  </div>
                  <span className='d-block pb-2 text-secondary'>Our company specializes in providing network energy installation services for telecommunication towers across all categories, including commercial, hybrid, diesel generator, solar power systems, and more. Additionally, we excel in back-up system installation.
                  </span>
                  <span className='d-block pb-3 text-secondary'>Our dedicated team of experts possesses extensive knowledge and technical expertise in implementing network energy installations to optimize performance and minimize downtime.</span>
                </div>
                <div className='col-12 col-lg-6 image'>
                  <img src={Services4} alt="Services 4" className='w-100 border-rounded'/>
                </div>
              </div>
            </div>
            <div id='Services5' className='py-5'>
              <div className='row mx-0 mt-3 g-4' data-aos="fade-right">
                <div className='col-12 col-lg-6 order-2 order-lg-1 image'>
                  <img src={Services5} alt="Services 5" className='w-100 border-rounded'/>
                </div>
                <div className='col-12 col-lg-6 order-1 order-lg-2'>
                  <div className='title d-flex justify-content-start py-3'>
                      <span>Managed  Services (MS)</span>
                  </div>
                  <span className='d-block pb-3 text-secondary'>Upper Telecom offers comprehensive Managed Services 24/7/365 for telecom networks, covering both active and passive components, as well as preventive maintenance (PM) and corrective maintenance (CM). Our services prioritize high responsiveness to ensure network availability remains at 100%.</span>
                </div>
              </div>
            </div>
            <div id='Services6' className='py-5'>
              <div className='row mx-0 mt-3 g-4' data-aos="fade-right">
                <div className='col-12 col-lg-6'>
                  <div className='title d-flex justify-content-start py-3'>
                      <span>Civil department</span>
                  </div>
                  <span className='d-block pb-1 text-secondary fw-bold'>Tower construction</span>
                  <span className='d-block pb-2 text-secondary'>We specialize in delivering exceptional network telecom tower construction services, leveraging our expertise to empower businesses to expand their network infrastructure and enhance connectivity.
                  </span>
                  <span className='d-block pb-3 text-secondary'>Our construction capabilities encompass various types of towers, including Macro Towers, Rooftop Towers, Monopoles, Stealth Towers, Palm Tree towers, and more.</span>
                </div>
                <div className='col-12 col-lg-6 image'>
                  <img src={Services6} alt="Services 6" className='w-100 border-rounded'/>
                </div>
              </div>
            </div>
            <div id='Services7' className='py-5'>
              <div className='row mx-0 mt-3 g-4' data-aos="fade-right">
                <div className='col-12 col-lg-6 order-2 order-lg-1'>
                  <div className='image mb-2'>
                    <img src={Services7} alt="Services 7" className='w-100 border-rounded'/>
                  </div>
                  <div className='image2'>
                    <img src={Services72} alt="Services 7" className='w-100 border-rounded'/>
                  </div>
                </div>
                <div className='col-12 col-lg-6 order-1 order-lg-2'>
                  <div className='title d-flex justify-content-start py-3'>
                      <span>Light civil constructions</span>
                  </div>
                  <span className='d-block pb-1 text-secondary fw-bold'>Sites Fencing and Security</span>
                  <span className='d-block pb-3 text-secondary'>We offer construction and installation of site fencing and security measures to safeguard telecom infrastructure from unauthorized access and potential security threats.</span>
                  <span className='d-block pb-1 text-secondary fw-bold'>Access Roads and Pathways</span>
                  <span className='d-block pb-3 text-secondary'>We offer construction of access roads and pathways to facilitate smooth accessibility to telecom sites, ensuring easy maintenance and operations. Our roads and pathways are designed to withstand heavy equipment.</span>
                  <span className='d-block pb-1 text-secondary fw-bold'>Cables Trenching and Ducting</span>
                  <span className='d-block pb-3 text-secondary'>Our skilled workforce efficiently undertakes cable trenching and ducting projects to create pathways for telecom cables and fiber optics. We ensure proper planning, excavation, and installation of conduits to facilitate seamless cable management and network connectivity.</span>
                  <span className='d-block pb-1 text-secondary fw-bold'>Equipment Shelters and Enclosures</span>
                  <span className='d-block pb-3 text-secondary'>We offer construction of equipment shelters and enclosures that house critical telecom equipment.</span>
                  <span className='d-block pb-1 text-secondary fw-bold'>Cables Tray and Canopy Installation</span>
                  <span className='d-block pb-3 text-secondary'> Our team is capable of installation cable tray including ladder trays, wire mesh cable tray and  canopy for weathering resistance.</span>
                </div>
              </div>
            </div>
            <div id='Services8' className='py-5'>
              <div className='row mx-0 mt-3 g-4' data-aos="fade-right">
                <div className='col-12 col-lg-6'>
                  <div className='title d-flex justify-content-start py-3'>
                      <span>Supplying Department</span>
                  </div>
                  <span className='d-block pb-3 text-secondary'>Upper Telecom offers a comprehensive range of accessories related to civil work, including microwave poles, GSM antenna poles, power cables, data cables such as Ethernet cables, circuit breakers, power distribution panels, IF connectors, 3M tapes, isolation tapes, IF cables, cable trays, PVC pipes, and more.</span>
                </div>
                <div className='col-12 col-lg-6 image'>
                  <img src={Services8} alt="Services 8" className='w-100 border-rounded'/>
                </div>
              </div>
            </div>
        </div>
    </section>
  )
}