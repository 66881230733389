import React from 'react'

export default function NotFound() {

    return (
        <section className='notFound m-0' id='notFound'>
            <div className='d-flex justify-content-center align-items-center' style={{"min-height":"54.7vh"}}>
                <span className='fw-bold fs-1'>Page Not Found Error 404</span>
            </div>
        </section>
    )
}
