import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './App.scss';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import About from './pages/About/About';
import NotFound from './pages/NotFound/NotFound';
import Footer from './Components/Footer/Footer';
import WhatsApp from './Components/WhatsApp/WhatsApp';
import ContactUs from './pages/ContactUs/ContactUs';
import Facebook from './Components/Facebook/Facebook';

export default function App() {
  return (
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route path={'/'} exact component={Home} />
          <Route path={'/services'} exact component={Services} />
          <Route path={'/about'} exact component={About} />
          <Route path={'/contact'} exact component={ContactUs} />
          <Route path={'*'} exact component={NotFound} />
        </Switch>
        <Footer />
        <WhatsApp />
        <Facebook/>
      </BrowserRouter>
  );
}
