import React, { useState } from 'react'
import { NavHashLink } from 'react-router-hash-link';

export default function Navbar() {
    const [nav, setNav] = useState(false)

    const changeNavBg = ()=>{
        if(window.scrollY >=80){
            setNav(true)
        }else{
            setNav(false)
        }
    }
    window.addEventListener('scroll',changeNavBg)

    return (
        <section className='sec-navbar' id="sec-navbar">
            <nav className={`navbar fixed-top navbar-light navbar-expand-lg d-flex ${nav? 'active':''}`}>
                <div className="container">
                    <NavHashLink className="logo navbar-brand mx-auto" to="/#sec-header" smooth="true">
                    </NavHashLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <NavHashLink className="offcanvas-title m-auto logo" id="offcanvasNavbarLabel" to="/#sec-header"></NavHashLink>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className={`navbar-nav m-auto mb-lg-0`} >
                                <li className="nav-item">
                                    <NavHashLink className="nav-link fw-bold fs-6" to="/#sec-header" smooth="true">Home</NavHashLink>
                                </li>
                                <li className="nav-item">
                                    <NavHashLink className="nav-link fw-bold fs-6" to="/services#services" smooth="true">Services</NavHashLink>
                                </li>
                                <li className="nav-item">
                                    <NavHashLink className="nav-link fw-bold fs-6" to="/about#about" smooth="true">About Us</NavHashLink>
                                </li>
                                <li className="nav-item">
                                    <NavHashLink className="nav-link fw-bold fs-6" to="/contact#contactUs" smooth="true">Contact Us</NavHashLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </section>
    )
}
