import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import AhmedOsman from "../../assets/images/Ahmed Osman.jpeg"
import EmadRamadan from "../../assets/images/Emad Ramadan.jpeg"
import MahmoudHassaan from "../../assets/images/Mahmoud Hassaan.jpeg"

export default function About() {

    useEffect(()=>{
        AOS.init({
          delay: 100, // values from 0 to 3000, with step 50ms
          duration: 800, // values from 0 to 3000, with step 50ms
          easing: 'ease-in-sine', // default easing for AOS animations
        });
      })

  return (
    <section className='about' id='about'>
        <div className='about mb-5 pb-5'>
            <div className='page-title title d-flex justify-content-center py-5'>
                <span className='mt-5 text-white fs-1'>ABOUT US</span>
            </div>
            <div className='container'>
                <div className='m-auto w-75 text-center my-5' data-aos="zoom-in-up">
                    <span className='text-secondary fs-4'>Upper Telecom Established in Assiut, Egypt in 2016, our company was founded by telecommunications experts with a shared goal: to introduce exceptional services to our valued customers. With a commitment to excellence and a passion for innovation, we aim to make a positive impact in telecom services, including new networks implementation, networks expansion, and light civil work. Our highly skilled team tirelessly works to understand and meet our customers' needs, combining technical proficiency with a deep understanding of their requirements to ensure the delivery of reliable and cost-effective telecommunications services.</span>
                </div>
            </div>
        </div>
        <div className='mission my-5 py-5' data-aos="zoom-in-down">
            <div className='container'>
                <div className='title d-flex justify-content-center py-5'>
                    <span>OUR MISSION</span>
                </div>
                <div className='m-auto w-75 text-center mb-5'>
                    <span className='text-secondary fs-4'>Our mission is to become the trusted partner for the seamless and efficient implementation of telecommunications infrastructure. We are dedicated to providing exceptional end-to-end implementation services, ensuring the successful deployment of advanced telecommunications networks and systems. Our goal is to empower our clients to harness cutting-edge technology and maximize their connectivity potential. We strive to offer comprehensive solutions tailored to the unique needs of each client, encompassing everything from project planning and site preparation to equipment installation and network optimization. With a focus on quality, reliability, and timely delivery, we aim to surpass our clients' expectations and contribute to their success. Through our technical expertise, industry knowledge, and robust partnerships, we are committed to driving digital transformation by offering seamless and reliable telecommunications implementation services. Our ultimate mission is to empower businesses and communities with robust communication infrastructure, enabling them to thrive in a connected world and unlock new opportunities for growth and innovation.</span>
                </div>
            </div>
        </div>
        <div className='objective my-5 py-5' data-aos="zoom-in-left">
            <div className='container'>
                <div className='title d-flex justify-content-center py-5'>
                    <span>OUR OBJECTIVE</span>
                </div>
                <div className='m-auto w-75 text-center mb-5'>
                    <span className='text-secondary fs-4'>At  Upper Telecom, our objectives are centered around delivering excellence in turnkey project management and exceeding client expectations. We are dedicated to achieving</span>
                </div>
                <div className='w-75 m-auto'>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button fw-bold fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Client Satisfaction  
                            </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                            We strive to establish long-term partnerships based on trust, reliability, and exceptional service.
                            </div>
                            </div>
                        </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed fw-bold fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Quality and Efficiency
                        </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                We aim to maintain the highest standards of quality and efficiency throughout every aspect of our turnkey projects. By employing industry best practices, leveraging advanced technologies, and engaging experienced professionals, we ensure successful project outcomes within agreed timelines and budgets.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed fw-bold fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Collaboration and Communication
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Effective collaboration and communication are paramount to our success. We strive to foster open and transparent communication channels with clients, stakeholders, and project team members, ensuring smooth coordination, prompt issue resolution, and a cohesive project experience.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed fw-bold fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Continuous Improvement
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                We embrace a culture of continuous improvement, consistently seeking ways to enhance our processes, capabilities, and service offerings. Through ongoing learning, skills development, and performance evaluation, we aim to be a leading force in the field of turnkey project management.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed fw-bold fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Safety and Sustainability 
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Our primary goal is to prevent accidents, incidents, and injuries. We implement robust safety procedures, risk assessments, and hazard identification measures to proactively address potential risks and hazards at every stage of our projects.
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='vision mt-5 py-5' data-aos="zoom-in-right">
            <div className='container'>
                <div className='title d-flex justify-content-center py-5'>
                    <span>OUR VISION</span>
                </div>
                <div className='m-auto w-75 text-center mb-5'>
                    <span className='text-secondary fs-4'>Our vision is to lead in managing turnkey projects by offering end-to-end solutions covering planning, execution, and delivery, thereby empowering our clients with seamless and successful project outcomes. We aim to expand our footprint across Africa, establishing a robust presence throughout the continent to contribute to its progress and become a trusted partner in driving expansion into new sectors.</span>
                </div>
            </div>
        </div>
        <div className='philosophy mt-5 py-5' data-aos="zoom-in-right">
            <div className='container'>
                <div className='title d-flex justify-content-center py-5'>
                    <span>OUR PHILOSOPHY</span>
                </div>
                <div className='m-auto w-75 text-center mb-5 d-flex flex-column'>
                    <span className='text-secondary fs-3 mb-2 fw-bold'>Empowering Businesses and Communities</span>
                    <span className='text-secondary fs-4 mb-3'>Upper Telecom's mission is to empower businesses and communities with robust communication infrastructure, enabling them to unlock new opportunities for growth and innovation.</span>
                    <span className='text-secondary fs-3 mb-2 fw-bold'>Contributing to Success</span>
                    <span className='text-secondary fs-4 mb-3'>The company aims to exceed its clients' expectations by providing exceptional end-to-end implementation services, contributing to their success and prosperity.</span>
                    <span className='text-secondary fs-3 mb-2 fw-bold'>Driving Digital Transformation</span>
                    <span className='text-secondary fs-4 mb-3'>Upper Telecom is committed to driving digital transformation through seamless and reliable telecommunications implementation services, leveraging its technical expertise, industry knowledge, and strong partnerships.</span>
                </div>
            </div>
        </div>
        <div className='why mt-5 py-5' data-aos="zoom-in-right">
            <div className='container'>
                <div className='title d-flex justify-content-center py-5'>
                    <span>WHY UPPER TELECOM!</span>
                </div>
                <div className='m-auto w-75 text-center mb-5'>
                    <div className='row g-3'>
                        <div className='col-12 col-lg-4'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-4'>
                                    <span className='me-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check2-square" viewBox="0 0 16 16">
                                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z"/>
                                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0"/>
                                        </svg>
                                    </span>
                                    <span className='text-secondary fs-4'>Dedication, Reliability and Loyalty</span>
                                </div>
                                <div className='d-flex align-items-center mb-4'>
                                    <span className='me-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check2-square" viewBox="0 0 16 16">
                                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z"/>
                                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0"/>
                                        </svg>
                                    </span>
                                    <span className='text-secondary fs-4'>Commitment</span>
                                </div>
                                <div className='d-flex align-items-center mb-4'>
                                    <span className='me-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check2-square" viewBox="0 0 16 16">
                                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z"/>
                                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0"/>
                                        </svg>
                                    </span>
                                    <span className='text-secondary fs-4'>Technical Expertise</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-4'>
                                    <span className='me-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check2-square" viewBox="0 0 16 16">
                                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z"/>
                                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0"/>
                                        </svg>
                                    </span>
                                    <span className='text-secondary fs-4'>Problem-Solving Abilities</span>
                                </div>
                                <div className='d-flex align-items-center mb-4'>
                                    <span className='me-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check2-square" viewBox="0 0 16 16">
                                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z"/>
                                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0"/>
                                        </svg>
                                    </span>
                                    <span className='text-secondary fs-4'>Communication and Collaboration</span>
                                </div>
                                <div className='d-flex align-items-center mb-4'>
                                    <span className='me-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check2-square" viewBox="0 0 16 16">
                                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z"/>
                                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0"/>
                                        </svg>
                                    </span>
                                    <span className='text-secondary fs-4'>Project Management</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-4'>
                                    <span className='me-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check2-square" viewBox="0 0 16 16">
                                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z"/>
                                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0"/>
                                        </svg>
                                    </span>
                                    <span className='text-secondary fs-4'>Management Experience</span>
                                </div>
                                <div className='d-flex align-items-center mb-4'>
                                    <span className='me-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check2-square" viewBox="0 0 16 16">
                                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z"/>
                                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0"/>
                                        </svg>
                                    </span>
                                    <span className='text-secondary fs-4'>Focusing on Details</span>
                                </div>
                                <div className='d-flex align-items-center mb-4'>
                                    <span className='me-3'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check2-square" viewBox="0 0 16 16">
                                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z"/>
                                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0"/>
                                        </svg>
                                    </span>
                                    <span className='text-secondary fs-4'>Team work</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='team mt-5 py-5' data-aos="zoom-in-left">
            <div className='container'>
                <div className='title d-flex justify-content-center py-5'>
                    <span>OUR TEAM</span>
                </div>
                <div className='m-auto w-75 text-center mb-5'>
                    <span className='text-secondary fs-4'>Great things in business are never done by one person; they're done by a team of people." – Steve Jobs.</span>
                </div>
                <div className='row m-5 g-3'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <div className="card px-5 pt-5">
                            <img src={AhmedOsman} className="card-img-top border border-0 rounded-circle" alt="team 1"/>
                            <div className="card-body">
                                <h5 className="card-title text-center">Ahmed Osman</h5>
                                <p className="card-text text-center">Chief Financial Officer (CFO)</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <div className="card px-5 pt-5">
                            <img src={EmadRamadan} className="card-img-top border border-0 rounded-circle" alt="team 1"/>
                            <div className="card-body">
                                <h5 className="card-title text-center">Emad Ramadan</h5>
                                <p className="card-text text-center">Chief Executive Officer (CEO)</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <div className="card px-5 pt-5">
                            <img src={MahmoudHassaan} className="card-img-top border border-0 rounded-circle" alt="team 1"/>
                            <div className="card-body">
                                <h5 className="card-title text-center">Mahmoud Hassaan</h5>
                                <p className="card-text text-center">Chief Technical Officer (CTO)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
    )
}
