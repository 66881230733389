import React from 'react'
import { NavHashLink } from 'react-router-hash-link';
import QR from '../../assets/images/QR code.png'

export default function Footer() {

  return (
    <section className='sec-footer pt-5 pb-2' id="sec-footer">
        <div className='container'>
            <div className='pt-5 pb-3 ps-5'>
                <div className='row g-3'>
                    <div className='col-12 col-md-6 col-lg-3'>
                        <div className='title mb-4'>
                            <span className='fs-4 fw-bold text-white'>COMPANY</span>
                        </div>
                        <div>
                            <ul>
                                <li className='mb-3'><NavHashLink to='/about#about' className='fs-6'>ABOUT US</NavHashLink></li>
                                <li className='mb-3'><NavHashLink to='/services#services' className='fs-6'>SERVICES</NavHashLink></li>
                                <li className='mb-3'><NavHashLink to='/contact#contactUs' className='fs-6'>CONTACT US</NavHashLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                        <div className='title mb-4'>
                            <span className='fs-4 mb-3 fw-bold text-white'>OUR SERVICES</span>
                        </div>
                        <div className=''>
                            <ul>
                                <li className='text-white fs-6 mb-3'>
                                    <NavHashLink to='/services#Services2'>RAN Installation</NavHashLink>
                                </li>
                                <li className='text-white fs-6 mb-3'>
                                    <NavHashLink to='/services#Services2' >Microwave Antennas installation</NavHashLink>
                                </li>
                                <li className='text-white fs-6 mb-3'>
                                    <NavHashLink to='/services#Services3'>Network Roll-out</NavHashLink>
                                </li>
                                <li className='text-white fs-6 mb-3'>
                                    <NavHashLink to='/services#Services7'>Light civil constructions</NavHashLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                        <div className='title mb-4'>
                            <span className='fs-4 fw-bold text-white'>CONTACT US</span>
                        </div>
                        <div className='mb-3'>
                            <div className='px-2'>
                                <span className='me-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                                    </svg>
                                </span>
                                <span className='text-white'>4 Street no. 7, Takseem Elhokokyeen, Assiut, Egypt.</span>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='px-2'>
                                <span className='me-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-map-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z"/>
                                    </svg>
                                </span>
                                <a href="https://maps.app.goo.gl/iae9RfymWY4vgnJA8" className='text-white'>Google Map</a>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='px-2 d-flex'>
                                <div className='me-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                    </svg>
                                </div>
                                <div className='d-flex flex-column'>
                                    <span className='text-white'>+201016304962</span>
                                    <span className='text-white'>+201061780722</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                        <div className='title mb-4'>
                            <span className='fs-4 fw-bold text-white'>Scan US</span>
                        </div>
                        <img src={QR} alt='QR Code'/>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <span className='text-white fw-bold'>
                    Copyright © 2024<span className='upper'> Upper Telecom </span>| Developed by
                    <a href='https://blackytreasure.com' className='blacky' target="_blank" rel="noreferrer"> Blackytreasure</a>
                </span>
            </div>
        </div>
    </section>
  )
}
